// NodeList forEach polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

(function($) {
    $(document).ready(function() {

        // ensures ie10 displays unsupported browser message
        document.documentElement.setAttribute('data-useragent', navigator.userAgent);

        $('.swiper-container').each(function(index, element) {
            var $this = $(this);
            $this.addClass('instance-' + index);
            var swiper = new Swiper('.instance-' + index, {
                // Navigation arrows
                navigation: {
                    nextEl: $this.data('button-next'),
                    prevEl: $this.data('button-prev')
                },
                on: {
                    init: () => {
                        $this
                            .find('.swiper-slide')
                            .not('.swiper-slide-active')
                            .attr('inert', 'inert');
                    },
                    slideChangeTransitionEnd: () => {
                        $this
                            .find('.swiper-slide')
                            .not('.swiper-slide-active')
                            .attr('inert', 'inert');
                        $this.find('.swiper-slide-active').removeAttr('inert');
                    }
                }
            });

            var carouselID = $(this).data('carousel-id');
            var $links = $('.slide-link[data-carousel-id="' + carouselID + '"]')

            $links.click( function (event) {
                event.preventDefault();
                swiper.slideTo($(this).data('slide-index') - 1); // Elemental indexes start at 1, but carousel starts at 0
            });

            swiper.on('slideChange', function() {
                $links.removeClass('active');
                $links.filter('[data-slide-index="' + (swiper.activeIndex + 1) + '"]').addClass('active');
            });
        });


        $('.modaal-action-gallery').modaal({
            type: 'image'
        });

        $('.content-container table').wrap('<div class="responsive-table"></div>');

        // check if we are anchoring to an accordion item
        if(window.location.hash) {
            var anchor = window.location.hash;

            if( anchor.match('^#ac-') ){
                var accordionItem = document.querySelector(anchor);
                accordionItem.open = true;
                accordionItem.scrollIntoView({behavior: 'smooth'});
            }
        }
    });
})(jQuery);

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

// data-match-bottom functionality
//
// Using a data-attribute to make the addition of this functionality
// transparent in the HTML.
//
// Used on Content Details to align the top of the optional right rail
// to the content container top.
//
// I would do this in CSS, but because of the HTML structure, it seems
// almost impossible to position this correctly otherwise - the
// elements are not related in any clear way.
function doMatchBottom() {
    if (window.matchMedia('(min-width: 75em)').matches) {
        matchBottomElements.forEach(element => {
            let match = document.getElementById(element.dataset.matchBottom);
            var matchPos = match.offsetTop;
            var parentPos = match.parentElement.offsetTop;
            var matchOffset = matchPos - parentPos;
            let matchStyle = window.getComputedStyle(match);
            let matchMarginTop = parseInt(matchStyle.marginTop, 10);
            let matchMarginBottom = parseInt(matchStyle.marginBottom, 10);
            let elementNewMarginTop =
                matchOffset + match.clientHeight + matchMarginTop + matchMarginBottom;
            element.style.marginTop = `${elementNewMarginTop}px`;
        });
    } else {
        matchBottomElements.forEach(element => {
            element.style.marginTop = '';
        });
    }
}

let matchBottomElements = document.querySelectorAll('[data-match-bottom]');

if (matchBottomElements) {
    doMatchBottom();
    window.addEventListener('resize', debounce(() => doMatchBottom(), 200));
}

// News & Events search form: add an 'x' to clear search
let eventSearchForm = document.getElementById('SearchFormEvents');

if (eventSearchForm && eventSearchForm.dataset.searched) {
    let eventSearchInput = document.getElementById('SearchFormEvents_query');

    if (eventSearchInput) {
        let x = document.createElement('a');
        let i = document.createElement('i');

        i.classList.add('fas', 'fa-times');
        x.classList.add('search-clear');
        x.setAttribute('href', location.pathname);

        x.insertAdjacentElement('afterbegin', i);
        eventSearchInput.insertAdjacentElement('afterend', x);
    }
}
